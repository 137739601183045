<template>
  <el-row :gutter="24">
    <el-col :xs="24" :sm="12" :md="8">
      <div class="wrap-mp">
        <div class="wp">
          <span></span>
          <div class="wrap-btn">
            <el-button size="small" @click="onRefresh">刷新</el-button>
            <el-button size="small" @click="onOpen">打开</el-button>
          </div>
        </div>
        <div class="wrap-tree">
          <el-tree
            ref="tree"
            :data="data"
            accordion
            :props="defaultProps"
            @node-expand="changeIcon"
            @node-collapse="changeIcon2"
            :default-expand-all="isCollapse"
            @node-click="handleNodeClick"
          >
            <template #default="{ node, data }">
              <div class="custom-tree-node">
                <el-icon
                  class="wrap-icon"
                  :class="node.data.Id == cIcon ? 'icollapse' : ''"
                  v-if="data.Children"
                  ><ArrowRightBold
                /></el-icon>
                <span>{{ node.label }}</span>
                <span v-if="data.Children">
                  <i> {{ data.Children.length }}</i>
                </span>
              </div>
            </template>
          </el-tree>
        </div>
      </div>
    </el-col>
    <el-col :xs="24" :sm="12" :md="16">
      <div>测试</div>
    </el-col>
  </el-row>
</template>

<script>
import { ArrowRightBold } from '@element-plus/icons'
import { ElMessage } from 'element-plus'

export default {
  components: {
    ArrowRightBold,
  },
  mounted() { 
    this.getJSXM()
  },
  data() {
    return {
      cIcon: "100",
      isCollapse: false,
      data: [],
      defaultProps: {
        children: "Children",
        label: "Name",
      },
    };
  },
  methods: {
      async getJSXM() {
      let me = this;
      await this.$ajax
        .get(
          this.$appConfig.apiUrls.ywxtApi +
          '/api/YW/GetJSXM?JSXMID='+this.$route.query.xmid
        )
        .then(function (response) {
          if (response.data.IsSuccess) {
            let res = response.data.Data;
            console.log('获取数据：',res);
            me.data = res;
          } else {
            ElMessage({
              type: 'error',
              message: response.data.ErrorMessage,
            })
          }
        })
        .catch(function (error) {
          console.log(error)
          ElMessage({
            type: 'error',
            message: error,
          })
        })
    },
    changeIcon(obj) {
      if (this.cIcon == 100) {
        this.cIcon = obj.Id
      }
    },
    changeIcon2() {
      this.cIcon = ''
    },
    onRefresh() {
      console.log('刷新');
    },
    onOpen() {
      console.log('打开');
    },
    handleNodeClick(data){
      if(data.Children==null){
         console.log(data);
      }
    },
  },
};
</script>

<style scoped>
.wrap-tree {
  border-top: 1px solid #d9d7d7;
  font-size: 14px;
}
.wrap-tree :deep(.el-tree-node__content) {
  width: 100%;
  border-bottom: 1px solid #ddd;
  height: 35px;
}
.wrap-tree :deep(.el-tree-node__content .el-icon-caret-right:before) {
  content: "";
}
.wrap-icon {
  transition: 0.3s;
  margin-right: 5px;
}
.icollapse {
  transform: rotate(90deg);
}
.wp > span {
  padding-left: 5px;
  line-height: 40px;
}
.wrap-mp {
  background: #ffffff;
  height: 650px;
  margin-bottom: 20px;
  border-radius: 3px;
}
.custom-tree-node {
  width: 100%;
}
.custom-tree-node span:first-child {
  align-items: center;
  vertical-align: middle;
}
.custom-tree-node span:nth-of-type(2) {
  float: right;
}
.custom-tree-node span:last-child i {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777777;
  border-radius: 10px;
  margin-right: 10px;
}
.wrap-btn {
  float: right;
  padding: 10px;
}
</style>